// ==========================================================================
// Main Entry Point
// ==========================================================================

// 1. Configuration and helpers
@use '_variables.scss' as vars;
@use '_mixins.scss' as mix;

// 2. Base styles
@use '_fonts.scss'; // Typography and font imports
@use '_global.scss'; // Base, element-level styles, and reset

// 3. Layout components
@use '_header.scss'; // Site header
@use '_main.scss'; // Main content area
@use '_footer.scss'; // Site footer

// 4. Components
@use '_buttons.scss'; // Button styles

// 5. Page-specific styles
// Add any page-specific style imports here

// 6. Utilities and overrides
// Add any utility classes or override styles here

@use '_mixins.scss' as mix;

/*!
 * Buttons Styles
 */
// Base Button Styles
// --------------------------------------------------------------------------
.btn {
  display: inline-block;
  text-align: center;
  padding: 0.8rem 2.4rem;
  @include mix.primary-font('regular');
  font-size: 1.6rem;
  line-height: 1.2;

  cursor: pointer;
  @include mix.hover-transition {
    text-decoration: none;
  }

  // Full width button
  &-block {
    width: 100%;
  }

  // Button Variants
  // ------------------------------------------------------------------------
  &-black {
    background-color: #212529;
    color: #f8f9fa;

    @include mix.hover-transition {
      background-color: #f8f9fa;
      color: #212529;
    }
  }

  &-white {
    background-color: #f8f9fa;
    color: #212529;

    @include mix.hover-transition {
      background-color: #d1e7dd;
      color: #212529;
    }
  }

  &-pink {
    background-color: #ffbfbf;
    color: #212529;

    @include mix.hover-transition {
      background-color: #212529;
      color: #f8f9fa;
    }
  }

  &-teal {
    background-color: #b6cdd3;
    color: #212529;

    @include mix.hover-transition {
      background-color: #212529;
      color: #f8f9fa;
    }
  }

  // Button Sizes
  // ------------------------------------------------------------------------
  &-sm {
    padding: 0.4rem 1.6rem;
    font-size: 1.2rem;
  }

  &-lg {
    padding: 1.6rem 3.2rem;
    font-size: 2.4rem;
  }
}

// Responsive Styles
// --------------------------------------------------------------------------
@include mix.breakpoint(md) {
  .btn {
    padding: 0.8rem 2.4rem;
    font-size: 2.4rem;
  }
}

@include mix.breakpoint(lg) {
  .btn {
    font-size: 2.4rem;
  }
}

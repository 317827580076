// Brand Colors
$brand-colors: (
  'wordmark': #605e67,
  'pink': #fac5c4,
  'teal': #d0ddd7,
  'rose': #d3cac5,
);

// Neutral Colors
$neutrals: (
  'white': #ffffff,
  'off-white': #f4f4f4,
  'black': #000000,
  'off-black': #26232c,
);

// Semantic Colors
$semantic-colors: (
  'green': (
    'dark': #115c36,
    'medium': #198c53,
    'bright': #18c76f,
  ),
  'berry': (
    'dark': #941e66,
    'medium': #b92d80,
    'bright': #ff91aa,
  ),
  'blue': (
    'dark': #123d80,
    'medium': #1b5ba0,
    'bright': #18b7fb,
  ),
  'orange': (
    'dark': #d66310,
    'medium': #eb850d,
    'bright': #ffc805,
  ),
);

// Grayscale
$grays: (
  'dark-1': #505050,
  'dark-2': #828282,
  'text': #383838,
  'light-1': #d8d9da,
  'light-2': #efeff0,
);

// Spacing
$spacing-unit: 8px;
$spacing: (
  'xs': $spacing-unit,
  'sm': $spacing-unit * 2,
  'md': $spacing-unit * 3,
  'lg': $spacing-unit * 4,
  'xl': $spacing-unit * 5,
);

// Breakpoints
$breakpoints: (
  'xs': 0,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
  'xxl': 1400px,
);

// Typography
$font-stacks: (
  'primary': (
    'family': '"Source Sans Pro", sans-serif',
    'weights': (
      'light': 300,
      'regular': 400,
      'medium': 500,
      'semibold': 600,
      'bold': 700,
    ),
  ),
  'secondary': (
    'family': '"Playfair Display", serif',
    'weights': (
      'regular': 400,
      'medium': 500,
      'semibold': 600,
      'bold': 700,
      'black': 900,
    ),
  ),
);

$type-scale: (
  'small': 1.2rem,
  // 12px
  'body': 1.6rem,
  // 16px
  'h6': 1.8rem,
  // 18px
  'h5': 2rem,
  // 20px
  'h4': 2.4rem,
  // 24px
  'h3': 3rem,
  // 30px
  'h2': 3.6rem,
  // 36px
  'h1': 4.8rem, // 48px
);

$line-heights: (
  'tight': 1.2,
  'normal': 1.5,
  'loose': 1.8,
);

$font-sizes: (
  'xs': 12px,
  'sm': 14px,
  'base': 16px,
  'lg': 18px,
  'xl': 24px,
  'xxl': 32px,
);

// Z-index layers
$z-layers: (
  'modal': 1000,
  'overlay': 900,
  'dropdown': 800,
  'header': 700,
  'footer': 600,
);

@use 'sass:map';

// Export commonly used colors as individual variables for backward compatibility
$tta-word-mark: map.get($brand-colors, 'wordmark');
$tta-pink: map.get($brand-colors, 'pink');
$tta-teal: map.get($brand-colors, 'teal');
$tta-rose: map.get($brand-colors, 'rose');

$sub-white: map.get($neutrals, 'off-white');
$sub-black: map.get($neutrals, 'off-black');
$pure-black: map.get($neutrals, 'black');
$pure-white: map.get($neutrals, 'white');

$dark-green: map.get(map.get($semantic-colors, 'green'), 'dark');
$medium-green: map.get(map.get($semantic-colors, 'green'), 'medium');
$bright-green: map.get(map.get($semantic-colors, 'green'), 'bright');

$dark-berry: map.get(map.get($semantic-colors, 'berry'), 'dark');
$medium-berry: map.get(map.get($semantic-colors, 'berry'), 'medium');
$bright-berry: map.get(map.get($semantic-colors, 'berry'), 'bright');

$dark-blue: map.get(map.get($semantic-colors, 'blue'), 'dark');
$medium-blue: map.get(map.get($semantic-colors, 'blue'), 'medium');
$bright-blue: map.get(map.get($semantic-colors, 'blue'), 'bright');

$dark-orange: map.get(map.get($semantic-colors, 'orange'), 'dark');
$medium-orange: map.get(map.get($semantic-colors, 'orange'), 'medium');
$bright-yellow: map.get(map.get($semantic-colors, 'orange'), 'bright');

$dark-gray-1: map.get($grays, 'dark-1');
$dark-gray-2: map.get($grays, 'dark-2');
$dark-gray-for-text: map.get($grays, 'text');
$light-gray-1: map.get($grays, 'light-1');
$light-gray-2: map.get($grays, 'light-2');

@use 'sass:math';
@use 'sass:map';
@use 'sass:color';

@mixin fserif {
  font-family: 'Playfair Display', serif;
}

@mixin fsans {
  font-family: 'Source Sans Pro', sans-serif;
}

// Responsive breakpoint manager
@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

// Flex center helper
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Text truncation
@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Hover transition
@mixin hover-transition($property: all, $duration: 0.3s) {
  transition: $property $duration ease-in-out;

  &:hover {
    @content;
  }
}

// Font size with rem
@mixin font-size($size) {
  @if map-has-key($font-sizes, $size) {
    font-size: map-get($font-sizes, $size);
    font-size: math.div(map-get($font-sizes, $size), 16px) * 1rem;
  } @else {
    @warn "Invalid font size: #{$size}.";
  }
}

// Spacing helper
@mixin spacing($type, $size) {
  @if map-has-key($spacing, $size) {
    #{$type}: map-get($spacing, $size);
  } @else {
    @warn "Invalid spacing size: #{$size}.";
  }
}

// Grid container with max-width
@mixin container($padding: $spacing-unit * 2) {
  width: 100%;
  max-width: map-get($breakpoints, 'xl');
  margin-right: auto;
  margin-left: auto;
  padding-right: $padding;
  padding-left: $padding;
}

// Hide visually but keep accessible for screen readers
@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@mixin breakpoint($point) {
  @if $point == sm {
    @media (min-width: 576px) {
      @content;
    }
  } @else if $point == md {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $point == lg {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $point == xl {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $point == xxl {
    @media (min-width: 1400px) {
      @content;
    }
  }
}

@mixin container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1.6rem;
  padding-left: 1.6rem;

  @include breakpoint(sm) {
    max-width: 540px;
  }
  @include breakpoint(md) {
    max-width: 720px;
  }
  @include breakpoint(lg) {
    max-width: 960px;
  }
  @include breakpoint(xl) {
    max-width: 1140px;
  }
  @include breakpoint(xxl) {
    max-width: 1320px;
  }
}

@mixin container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1.6rem;
  padding-left: 1.6rem;
}

@mixin row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1.6rem;
  margin-left: -1.6rem;
}

@mixin col($size: 12) {
  flex: 0 0 auto;
  width: math.percentage(math.div($size, 12));
  padding-right: 1.6rem;
  padding-left: 1.6rem;
}

@mixin primary-font($weight: 'regular') {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: map.get(
    (
      'light': 300,
      'regular': 400,
      'medium': 500,
      'semibold': 600,
      'bold': 700,
    ),
    $weight
  );
}

@mixin secondary-font($weight: 'regular') {
  font-family: 'Playfair Display', serif;
  font-weight: map.get(
    (
      'regular': 400,
      'medium': 500,
      'semibold': 600,
      'bold': 700,
      'black': 900,
    ),
    $weight
  );
}

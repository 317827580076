// ==========================================================================
// Global Styles & Reset
// ==========================================================================
@use '_mixins.scss' as mix;

// Reset
// --------------------------------------------------------------------------
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%; // This makes 1rem = 10px for easier calculations
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  @include mix.primary-font('regular');
  font-size: 1.6rem;
  line-height: 1.5;
  color: #212529;
  background-color: #ffffff;
  min-height: 100vh;

  // Layout structure using flexbox
  display: flex;
  flex-direction: column;

  // Component ordering
  header {
    order: 0;
  }
  #main {
    order: 1;
    flex: 1 0 auto; // Push footer to bottom
  }
  footer {
    order: 2;
  }

  // Hide prompt element
  .prompt {
    display: none;
  }
}

// Base Elements
// --------------------------------------------------------------------------
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

// Typography
// --------------------------------------------------------------------------
h1,
h2,
h3,
h4,
h5,
h6 {
  @include mix.secondary-font('bold');
  margin-top: 0;
  margin-bottom: 1.6rem;
  line-height: 1.2;
}

p {
  margin-bottom: 1.6rem;
}

a {
  color: inherit;
  text-decoration: none;

  @include mix.hover-transition {
    color: #0d6efd;
  }
}

// Animations
// --------------------------------------------------------------------------
header,
footer {
  animation: fadeIn 1.5s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Accessibility
// --------------------------------------------------------------------------
:focus-visible {
  outline: 3px solid #cfe2ff;
  outline-offset: 2px;
}

:focus:not(:focus-visible) {
  outline: none;
}

// Print styles
// --------------------------------------------------------------------------
@media print {
  body {
    color: #000;
    background: #fff;
  }

  header,
  footer {
    display: none;
  }
}

// Layout Classes
// --------------------------------------------------------------------------
.container {
  @include mix.container;
}

.container-fluid {
  @include mix.container-fluid;
}

.row {
  @include mix.row;
}

.col-12 {
  @include mix.col(12);
}

// Utility Classes
// --------------------------------------------------------------------------
.pink-blue-gradient {
  background-image: linear-gradient(
    360deg,
    rgb(255, 226, 224) 0%,
    rgb(182, 205, 211) 100%
  ) !important;
}
.blue-pink-gradient {
  background-image: linear-gradient(
    360deg,
    rgb(182, 205, 211) 0%,
    rgb(255, 226, 224) 100%
  ) !important;
}

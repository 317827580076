// ==========================================================================
// Footer Styles
// ==========================================================================
@use '_variables.scss' as vars;
@use '_mixins.scss' as mix;
@use 'sass:map';

#footer {
  background-color: #f8f9fa;
  color: map.get(vars.$brand-colors, 'wordmark');
  height: 8rem;
  @include mix.flex-center;
  z-index: 900;

  #tta-nav {
    width: 100vw;
    @include mix.flex-center;

    a {
      @include mix.primary-font('regular');
      color: map.get(vars.$brand-colors, 'wordmark');
      width: 20vw;
      height: 8rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      @include mix.hover-transition {
        text-decoration: none;
        color: #212529;

        svg {
          color: #212529;
        }
      }

      .link-icon {
        @include mix.flex-center;

        img {
          width: auto;
          height: 4rem;
        }

        svg {
          width: auto;
          height: 4rem;
          color: map.get(vars.$brand-colors, 'wordmark');
          transition: color 0.3s ease;
        }
      }

      .link-text {
        font-size: 1.2rem;
        line-height: 1.2;
        text-transform: uppercase;
        text-align: center;
      }
    }
  }
}

// Responsive Styles
// --------------------------------------------------------------------------
@include mix.breakpoint(md) {
  #footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    #tta-nav {
      width: 50vw;
    }
  }
}

@include mix.breakpoint(lg) {
  #footer {
    #tta-nav {
      width: 30vw;
    }
  }
}

// ==========================================================================
// Header Styles
// ==========================================================================
@use '_variables.scss' as vars;
@use '_mixins.scss' as mix;
@use 'sass:map';

header {
  background-color: #f8f9fa;
  position: static;

  .col-12 {
    @include mix.flex-center;
    height: 5rem;

    svg {
      color: map.get(vars.$brand-colors, 'wordmark');
      width: auto;
      height: 4rem;
    }
  }
}

// Responsive Styles
// --------------------------------------------------------------------------
@include mix.breakpoint(md) {
  header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 900;
  }
}

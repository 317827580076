@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../fonts/source-sans-pro-v18-latin-300.a4af4b34.woff2") format("woff2");
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/source-sans-pro-v18-latin-regular.211c70b7.woff2") format("woff2");
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/source-sans-pro-v18-latin-600.c0d946ee.woff2") format("woff2");
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/source-sans-pro-v18-latin-700.0ed89466.woff2") format("woff2");
}

@font-face {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/playfair-display-v25-latin-regular.a4d2bb9d.woff2") format("woff2");
}

@font-face {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/playfair-display-v25-latin-500.f613dd37.woff2") format("woff2");
}

@font-face {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/playfair-display-v25-latin-600.29c613ea.woff2") format("woff2");
}

@font-face {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/playfair-display-v25-latin-700.77d44057.woff2") format("woff2");
}

@font-face {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 900;
  src: local(""), url("../fonts/playfair-display-v25-latin-900.467d75f4.woff2") format("woff2");
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-size: 62.5%;
}

body {
  color: #212529;
  background-color: #fff;
  flex-direction: column;
  min-height: 100vh;
  font-family: Source Sans Pro, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

body header {
  order: 0;
}

body #main {
  flex: 1 0 auto;
  order: 1;
}

body footer {
  order: 2;
}

body .prompt {
  display: none;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1.6rem;
  font-family: Playfair Display, serif;
  font-weight: 700;
  line-height: 1.2;
}

p {
  margin-bottom: 1.6rem;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all .3s ease-in-out;
}

a:hover {
  color: #0d6efd;
}

header, footer {
  animation: 1.5s forwards fadeIn;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

:focus-visible {
  outline-offset: 2px;
  outline: 3px solid #cfe2ff;
}

:focus:not(:focus-visible) {
  outline: none;
}

@media print {
  body {
    color: #000;
    background: #fff;
  }

  header, footer {
    display: none;
  }
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

.container-fluid {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.row {
  flex-wrap: wrap;
  margin-left: -1.6rem;
  margin-right: -1.6rem;
  display: flex;
}

.col-12 {
  flex: none;
  width: 100%;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.pink-blue-gradient {
  background-image: linear-gradient(360deg, #ffe2e0 0%, #b6cdd3 100%) !important;
}

.blue-pink-gradient {
  background-image: linear-gradient(360deg, #b6cdd3 0%, #ffe2e0 100%) !important;
}

header {
  background-color: #f8f9fa;
  position: static;
}

header .col-12 {
  justify-content: center;
  align-items: center;
  height: 5rem;
  display: flex;
}

header .col-12 svg {
  color: #605e67;
  width: auto;
  height: 4rem;
}

@media (min-width: 768px) {
  header {
    z-index: 900;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

#main {
  width: 100vw;
  height: calc(100vh - 130px);
}

#main #slide_shop {
  background-color: #fac5c4;
  background-image: url("../img/rajat-sarki-MS75jlbCaBc-unsplash.7d4f783d.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

#main #slide_shop .content {
  opacity: 1;
  background-color: #f4f4f4bf;
  border-radius: .8rem;
  min-width: 33.5rem;
  margin: 0 2.5rem;
  padding: 1.5rem;
}

#main #slide_shop .content h1, #main #slide_shop .content h2, #main #slide_shop .content p {
  margin: 0;
}

#main #slide_shop .content .headings {
  color: #26232c;
  margin-bottom: .8rem;
  font-size: 2.2rem;
  line-height: 1.4;
}

#main #slide_shop .content .copies {
  color: #505050;
  font-size: 1.6rem;
  line-height: 1.4;
}

#main #slide_shop .content .cta-wrapper {
  margin-top: 1.5rem;
}

@media (min-width: 768px) {
  #main #slide_shop {
    background-image: url("../img/rajat-sarki-MS75jlbCaBc-unsplash.b829eb2f.webp");
  }

  #main #slide_shop .content {
    width: 50vw;
  }

  #main #slide_shop .content .headings {
    margin-bottom: 1rem;
    font-size: 3rem;
    line-height: 1.1;
  }

  #main #slide_shop .content .copies {
    font-size: 2.2rem;
    line-height: 1.4;
  }
}

#footer {
  color: #605e67;
  z-index: 900;
  background-color: #f8f9fa;
  justify-content: center;
  align-items: center;
  height: 8rem;
  display: flex;
}

#footer #tta-nav {
  justify-content: center;
  align-items: center;
  width: 100vw;
  display: flex;
}

#footer #tta-nav a {
  color: #605e67;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 20vw;
  height: 8rem;
  font-family: Source Sans Pro, sans-serif;
  font-weight: 400;
  transition: all .3s ease-in-out;
  display: flex;
}

#footer #tta-nav a:hover {
  color: #212529;
  text-decoration: none;
}

#footer #tta-nav a:hover svg {
  color: #212529;
}

#footer #tta-nav a .link-icon {
  justify-content: center;
  align-items: center;
  display: flex;
}

#footer #tta-nav a .link-icon img {
  width: auto;
  height: 4rem;
}

#footer #tta-nav a .link-icon svg {
  color: #605e67;
  width: auto;
  height: 4rem;
  transition: color .3s;
}

#footer #tta-nav a .link-text {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.2;
}

@media (min-width: 768px) {
  #footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  #footer #tta-nav {
    width: 50vw;
  }
}

@media (min-width: 992px) {
  #footer #tta-nav {
    width: 30vw;
  }
}

.btn {
  text-align: center;
  cursor: pointer;
  padding: .8rem 2.4rem;
  font-family: Source Sans Pro, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2;
  transition: all .3s ease-in-out;
  display: inline-block;
}

.btn:hover {
  text-decoration: none;
}

.btn-block {
  width: 100%;
}

.btn-black {
  color: #f8f9fa;
  background-color: #212529;
  transition: all .3s ease-in-out;
}

.btn-black:hover {
  color: #212529;
  background-color: #f8f9fa;
}

.btn-white {
  color: #212529;
  background-color: #f8f9fa;
  transition: all .3s ease-in-out;
}

.btn-white:hover {
  color: #212529;
  background-color: #d1e7dd;
}

.btn-pink {
  color: #212529;
  background-color: #ffbfbf;
  transition: all .3s ease-in-out;
}

.btn-pink:hover {
  color: #f8f9fa;
  background-color: #212529;
}

.btn-teal {
  color: #212529;
  background-color: #b6cdd3;
  transition: all .3s ease-in-out;
}

.btn-teal:hover {
  color: #f8f9fa;
  background-color: #212529;
}

.btn-sm {
  padding: .4rem 1.6rem;
  font-size: 1.2rem;
}

.btn-lg {
  padding: 1.6rem 3.2rem;
  font-size: 2.4rem;
}

@media (min-width: 768px) {
  .btn {
    padding: .8rem 2.4rem;
    font-size: 2.4rem;
  }
}

@media (min-width: 992px) {
  .btn {
    font-size: 2.4rem;
  }
}

/*# sourceMappingURL=index.a269ea43.css.map */

@use 'sass:math';
@use '_variables.scss' as vars;
@use '_mixins.scss' as mix;

#main {
  width: 100vw;
  height: calc(100vh - 130px);

  #slide_shop {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background-color: vars.$tta-pink;
    background-image: linear-gradient(
      360deg,
      rgba(250, 197, 196, 1) 30%,
      rgba(208, 221, 215, 1) 75%
    );
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // filter: blur(2px);
    // -webkit-filter: blur(2px);
    background-image: url(../img/rajat-sarki-MS75jlbCaBc-unsplash.jpg?as=webp&width=992);

    .content {
      min-width: 33.5rem;
      margin: 0 2.5rem;
      padding: 1.5rem;
      background-color: #f4f4f4bf;

      opacity: 1;
      border-radius: 0.8rem;

      h1,
      h2,
      p {
        margin: 0;
      }

      .headings {
        color: vars.$sub-black;
        font-size: 2.2rem;
        line-height: 1.4;
        margin-bottom: 0.8rem;
      }

      .copies {
        font-size: 1.6rem;
        line-height: 1.4;
        color: vars.$dark-gray-1;
      }

      .cta-wrapper {
        margin-top: 1.5rem;
      }
    }
  }
}

@include mix.breakpoint(md) {
  #main {
    #slide_shop {
      background-image: url(../img/rajat-sarki-MS75jlbCaBc-unsplash.jpg?as=webp&width=1200);

      .content {
        width: 80vw;

        .headings {
          font-size: 3.2rem;
          line-height: 1.4;
          margin-bottom: 0.8rem;
        }

        .copies {
          font-size: 2.4rem;
          line-height: 1.4;
        }
      }
    }
  }
}

@include mix.breakpoint(md) {
  #main {
    #slide_shop {
      background-image: url(../img/rajat-sarki-MS75jlbCaBc-unsplash.jpg?as=webp&width=1440);
      .content {
        width: 50vw;

        .headings {
          font-size: 3rem;
          line-height: 1.1;
          margin-bottom: 1rem;
        }

        .copies {
          font-size: 2.2rem;
          line-height: 1.4;
        }
      }
    }
  }
}

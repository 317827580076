// Font configuration and imports
// This file serves as the main entry point for typography

// Font mixins for consistent usage
@mixin primary-font($weight: 'regular') {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: map-get(
    (
      'light': 300,
      'regular': 400,
      'medium': 500,
      'semibold': 600,
      'bold': 700,
    ),
    $weight
  );
}

@mixin secondary-font($weight: 'regular') {
  font-family: 'Playfair Display', serif;
  font-weight: map-get(
    (
      'regular': 400,
      'medium': 500,
      'semibold': 600,
      'bold': 700,
      'black': 900,
    ),
    $weight
  );
}

// Typography scale
$type-scale: (
  'heading-1': (
    'size': 2.5rem,
    'line-height': 1.2,
    'font': 'secondary',
  ),
  'heading-2': (
    'size': 2rem,
    'line-height': 1.3,
    'font': 'secondary',
  ),
  'heading-3': (
    'size': 1.75rem,
    'line-height': 1.4,
    'font': 'secondary',
  ),
  'body': (
    'size': 1rem,
    'line-height': 1.5,
    'font': 'primary',
  ),
  'small': (
    'size': 0.875rem,
    'line-height': 1.4,
    'font': 'primary',
  ),
);

// Source Sans Pro
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src:
    local(''),
    url('../fonts/source-sans-pro-v18-latin-300.woff2') format('woff2');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('../fonts/source-sans-pro-v18-latin-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src:
    local(''),
    url('../fonts/source-sans-pro-v18-latin-600.woff2') format('woff2');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('../fonts/source-sans-pro-v18-latin-700.woff2') format('woff2');
}

// Playfair Display
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('../fonts/playfair-display-v25-latin-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 500;
  src:
    local(''),
    url('../fonts/playfair-display-v25-latin-500.woff2') format('woff2');
}

@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  src:
    local(''),
    url('../fonts/playfair-display-v25-latin-600.woff2') format('woff2');
}

@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('../fonts/playfair-display-v25-latin-700.woff2') format('woff2');
}

@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 900;
  src:
    local(''),
    url('../fonts/playfair-display-v25-latin-900.woff2') format('woff2');
}
